<template>
    <div id="app" class="main-wrapper">
        <c-error v-if="ERROR"></c-error>
        <template v-else>
        <c-header v-if="LOGGED && $route.name !== 'login'" />
        <div class="content-wrapper">
            <router-view></router-view>
        </div>
        <c-footer v-if="LOGGED && $route.name !== 'login'"  />
        </template>
    </div>
</template>

<script>
    import cHeader from "@/components/cHeader";
    import cFooter from "@/components/cFooter";
    import cError from "@/components/cError";
    import {mapMutations, mapGetters} from 'vuex';

    export default {
        name: "c-base",
        components: {
            cError,
            cHeader,
            cFooter,
            //cOrderList
        },
        computed: {
            ...mapGetters([
                'LOGGED',
                'ERROR'
            ])
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        methods: {
            ...mapMutations([
               'SET_WINDOW_WIDTH'
            ]),
            handleResize() {
                this.SET_WINDOW_WIDTH(window.innerWidth);
            }
        },
    }
</script>

<style>
@import "../../node_modules/animate.css/animate.min.css";
/*@import "../assets/styles/preset.css";*/
/*@import "../assets/styles/main.css";*/
/*@import "../assets/styles/reset.css";*/

</style>
